import type { GetStaticProps } from 'next';
import { NextPageWithLayout } from './_app';
import StandardPage, { getStandardStaticProps, standardPageLayout } from '@/components/layout/standard-page';
import { withTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

const HomePage: NextPageWithLayout = withTranslation('common')(props => <StandardPage {...props} />);
HomePage.getLayout = standardPageLayout();
export default HomePage;

export const getStaticProps: GetStaticProps = getStandardStaticProps({
    ssrConfig: locale => serverSideTranslations(locale, ['common']),
    slugFunction: () => '_homepage',
});
